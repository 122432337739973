@use "../utils" as util;
@import "../utils";

.section-1 {
	display: flex;
	justify-content: center;
	padding-top: 180px;

	&__wrapper {
		display: flex;
		flex-direction: row;
		gap: 5rem;
		width: 100%;
	}

	.quick-facts {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 56px;
		order: 0;
		align-self: stretch;
		flex-grow: 1;
		background: map-get($colors, "white");
		border: 1px solid map-get($colors, "mystic");
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		width: 100%;

		.icon-sets-wrapper {
			display: flex;
			gap: 4rem;
		}

		img {
			width: 100%;
			border-radius: 16px 16px 0 0;
		}

		&__image {
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				position: absolute;
				border: none;
				background-color: transparent;
				transition-duration: 500ms;

				img {
					width: 64px;
				}

				&:hover {
					scale: 1.3;
					transition-duration: 500ms;
				}
			}
		}

		&__icon-sets-container {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			gap: 64px;
			padding: 0 32px 40px 32px;
			align-self: stretch;
			flex-grow: 1;
		}

		&__icon-sets {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 24px;
			text-align: center;
			flex-grow: 1;

			img {
				width: 64px;
			}
		}
	}
}
