@use "../utils" as util;
@import "../utils";

.state {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 5rem 2rem;
	gap: 64px;
	isolation: isolate;
	//max-width: 1900px;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		#edf2f4 44.79%
	);

	&__hero {
		width: 100%;
		position: relative;

		h1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			padding: 16px 64px;
			background: rgba(0, 0, 0, 0.7);
			border-radius: 16px;
			@include util.ywh-h1-bold;
			color: map-get($colors, "white");
			text-align: center;
			text-transform: uppercase;
		}

		img {
			width: 100%;
			max-height: 600px;
			object-fit: cover;
		}
	}

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 0;
		gap: 16px;
		width: 100%;
		position: relative;

		.header-icon {
			width: 280px;
		}

		h2 {
			@include util.ywh-h2-bold;
			color: map-get($colors, "royalBlue");
			margin-top: -80px;
		}

		h3 {
			max-width: 950px;
		}

		.header-description {
			@include util.ywh-h3-regular;
		}
	}
}

.state-accordion {
	display: flex;
	flex-direction: column;
	padding: 80px 0 160px 0;
	isolation: isolate;
	width: 1200px;

	&__header {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		padding: 24px 32px;
		width: 100%;
		background: map-get($colors, "azure");
		//border-top: 2px solid map-get($colors, "conflowerBlue");
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
		cursor: pointer;
		transition: 0.4s;
		position: relative;

		&:hover {
			background-color: map-get($colors, "royalBlue");
		}

		h4 {
			@include util.ywh-h4-medium;
			color: map-get($colors, "white");
		}

		.state-accordion__icon {
			font-weight: bold;
			color: map-get($colors, "white");
			transition: 0.4s;
		}
	}

	.accordion-top {
		border-radius: 16px 16px 0 0;
		border-top: none;
	}

	&__content {
		padding: 0;
		background: map-get($colors, "lavender");
		border-radius: 0;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
		position: relative;

		.content-wrapper {
			padding: 32px 32px 22px 32px;
		}

		ul li {
			@include util.ywh-h6-regular;
			padding: 5px 0;
		}

		a {
			color: map-get($colors, "nero");
		}

		a:hover {
			color: map-get($colors, "azure");
			font-weight: 600;
		}

		ul li:hover {
			list-style-type: circle;
		}
	}

	.active {
		.state-accordion__header {
			background-color: map-get($colors, "royalBlue");
			z-index: 10;
		}

		.state-accordion__icon {
			transform: rotate(45deg);
			transition: 0.4s;
		}
	}
}
