.top-header__wrapper {
    gap: 8px;
		z-index: 1;
    & .top-header__logo {
        max-width: 100%;
    }

    .top-header__nav-items {
        gap: .75rem;
    }
    .top-header__contact {
        display: flex !important;
        border-left: none;
        padding-left: 0px;

        & span {
            font-size: 16px;
        }
    }
    @media (min-width: 992px) {
        flex-direction: row !important;
        .top-header__nav-items {
            gap: 2rem;
        }
        .top-header__contact {
           border-left: 1px dashed #787878;
           padding-left: 20px;
        }

        & span.top-header__contact--company {
            font-size: 1.28rem !important;
        }
    }
    
}

.toggle-menu-container {
    background-color: #1F1F1F;
    border-radius: unset;
    padding: 8px;
    justify-content: center;
    min-height: 56px;
    & .toggle-menu-close {
        color: white;
        font-size: 4.125rem;
        position: absolute;
        top: -30px;
        font-weight: 300;
    }
}

.ywh-navbar {
    @media (max-width: 992px) {
        flex-direction: column;
        padding-left: 0px;
        align-items: center;
        display: none;
        transition: all 1s ease-in-out;

        &.toggle-menu {
            display: block;
        }
    }
}