//pulse border animation
@keyframes pulse-border {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.2);
	}
}
