.selected {
    background-color: gray;
}

ul li {
    list-style: none;
}

#assign-btn {
    width: 300px !important;
    margin-top: 8px;
}

.page-label {
    font-size: 15px !important;
}

.profile-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.agent-li {
    margin-bottom: 12px;
}