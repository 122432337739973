.footer-section {
  .footer {
    background-color: #000;
    background-image: url('https://bucket.yourwayhome.com/web-assets/yourwayhome/footer/footer-background.webp');

    .partner__heading {
      color: #fff;
      font-weight: 500;
    }

    .partner__text {
      font-size: 17px;
      font-weight: 400;
      line-height: 27.54px;
    }

    .contents {
      gap: 4rem;
    }
    .idfpr-contacts {
      font-size: 1rem;

      @media (min-width: 993px) {
        font-size: 1.3rem;
      }
    }
  }

  & .footer__wrapper {
    max-width: 100%;
  }

  .footer__ywh-logo img {
    max-width: 750px;
    width: 100%;
  }

  .flex-login {
    display: flex;
    align-items: center;
    gap: 16px;
    &.flex-col {
      flex-direction: column;
    }
    &.gap-8 {
      gap: 8px;
      font-size: 12px;
    }
  }
}
