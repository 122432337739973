@use "../utils" as util;
@use "../../../node_modules/bootstrap/scss/bootstrap" as bs;
@import "../utils";

.footer-section {
	display: flex;
	justify-content: center;

	.footer {
		flex-direction: column;
		align-items: center;
		background-image: url("https://bucket.ywhdev.com/wl/?id=v7jXzpHFui0pAbHbjkt1qETEpbkK73RS&fmode=open");
		width: 100%;
		height: 100%;
		padding: 64px 24px;
		border-top: 10px solid #7ba4e9;
		@media (min-width: 993px) {
			padding: 96px
		}

		&__wrapper {
			max-width: 1500px;
			flex-direction: column;
			justify-content: center;
			gap: 4rem;
		}

		.header {
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 2rem;
			text-align: center;

			h2 {
				@include util.ywh-h2-regular;
				font-family: map-get($font-family, "playfair");
			}

			h2,
			p {
				color: map-get($colors, "white");
			}

			&__hr {
				width: 300px;
				height: 4px;
				background: map-get($colors, "tan");
				border-radius: 10px;
			}
		}

		&__hr {
			width: 100%;
			border: 1px dashed map-get($colors, "gray");
		}

		&__ywh-logo {
			justify-content: center;
			align-items: end;
			gap: 5rem;
		}

		.contents {
			flex-direction: row;
			padding: 0px;
			gap: 8rem;
			justify-content: center;

			&__col {
				flex-direction: column;
				gap: 3rem;
			}

			.text,
			a {
				color: map-get($colors, "white");
				@include util.ywh-caption;
			}

			a:hover {
				color: map-get($colors, "tumbleweed");
			}

			.contacts {
				flex-direction: column;

				&__info {
					flex-direction: row;
					gap: 1rem;
				}

				img {
					width: 20px;
				}
			}

			.socmed {
				flex-direction: row;
				gap: 1rem;

				img {
					width: 30px;
				}
			}

			.navigation {
				flex-direction: column;
				gap: 1rem;
			}

			.footer-nav-wrapper {
				display: flex;
				gap: 8rem;
			}
		}

		.partner {
			display: flex;
			flex-direction: column;
			gap: 3rem;
			align-items: center;
			text-align: center;

			&__text {
				color: map-get($colors, "white");
				@include util.ywh-caption;
			}

			h6 {
				@include util.ywh-h6-bold;
				color: map-get($colors, "white");
			}

			button {
				padding: 12px 26px;
				background: map-get($colors, "vistaBlue");
				border: none;
				border-radius: 50px;
				@include util.ywh-button-large;
				transition-duration: 500ms;

				&:hover {
					background: map-get($colors, "azure");
					border-radius: 10px;
					transition-duration: 500ms;
				}
			}
		}

		&__notes {
			flex-direction: column;
			align-items: center;
			gap: 2rem;
			color: map-get($colors, "dimGray");

			.idfpr {
				flex-direction: column;
				align-items: center;
				width: 60%;
				text-align: center;
				gap: 0.5rem;

				h5 {
					@include util.ywh-h5-bold;
				}

				&__address {
					@include util.ywh-caption;
				}
			}

			.idfpr-contacts {
				flex-direction: row;
				gap: 1rem;
				@include util.ywh-body;
			}

			.footnote {
				flex-direction: column;
				align-items: center;
				text-align: center;

				h6 {
					@include util.font-set--fs-fw-lh(caption, bold, "1.5");
					color: map-get($colors, "silverGray");
				}

				.endnote {
					@include util.ywh-endnote;
				}
			}

			.copyright {
				@include util.ywh-caption;
			}
		}
	}
}
