.content-body {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    background-color: white !important;
		position: relative;
}
.sticky-button {
    bottom: unset;
    right: -77px;
    z-index: 15;
    top: 50%;
    transform: rotate(-90deg);
    margin: 0px;
    background-color: var(--ywh-royal-blue, #04246B);
    border-radius: 5px 5px 0px 0px;
}

nav {
    z-index: 1000;
}