.no-click {
  pointer-events: none !important;
}

.city {
  @media (max-width: 992px) {
    padding: 2.5rem 0rem;
  }
}

.state-accordion {
  max-width: 800px;
  width: 100%;
  padding: 2.5rem 0;
  @media (min-width: 994px) {
    max-width: 1000px;
    padding: 80px 64px;
  }

  & .state-accordion__item {
    & h4 {
      font-size: 30px;
      line-height: 39px;
      color: white;
      font-weight: 700;

      @media (max-width: 600px) {
        font-size: 1.25rem;
      }
    }

    & .content-wrapper {
      & ul {
        margin-bottom: 0px;
        & li {
          list-style: disc !important;
          a {
            font-size: 24px;
            @media (max-width: 600px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
