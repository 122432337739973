.community-page-custom-toast {
    .Toastify__toast-container {
        min-width: 320px;
        max-width: 600px;
        width: 100%;
    }
}

.main-community {
    margin-left: 10% !important;
    margin-right: 10% !important;
    @media (max-width: 992px) {
        margin: 0px !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}
