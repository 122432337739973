@use "../utils" as util;
@import "../utils";

.builder {
	flex-direction: column;
	gap: 6rem;

	.intro {
		flex-direction: column;
		align-items: center;
		padding: 0 5rem;
		gap: 32px;
		width: 90%;
		margin: auto;

		img {
			width: 250px;
		}

		&__hr {
			width: 100%;
			height: 4px;
			background: map-get($colors, "alto");
			border-radius: 10px;
		}

		h6 {
			@include util.ywh-h6-medium;
			text-align: center;
		}
	}
	&__text-banner {
		width: 80%;
		margin: auto;
		text-align: center;

		h4 {
			@include util.ywh-h4-bold;
		}
	}
}
