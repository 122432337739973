@use "../utils" as util;
@import "../utils";

.heading {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	&__wrapper {
		position: absolute;
		max-width: 1200px;
		background: #ffffff;
		box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
		border-radius: 8px;
		padding: 32px 48px;
		margin: 0 3rem 0 3rem;
		gap: 8rem;
	}

	&__col-1 {
		h1 {
			@include util.ywh-h1-bold;
		}

		h4 {
			@include util.ywh-h4-bold;
		}
	}

	&__col-2 {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;
	}

	&__button {
		width: 250px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 0 16px 0;
		gap: 0.5rem;
		background: map-get($colors, "royalBlue");
		color: map-get($colors, "white");
		border-radius: 100px;
		border: none;
		transition-duration: 500ms;

		img {
			max-width: 23px;
		}

		&:hover {
			background: map-get($colors, "vistaBlue");
			border-radius: 10px;
			transition-duration: 500ms;
		}
	}

	&__btn-text {
		@include util.ywh-button-large;
	}

	&__share-wrapper {
		flex-direction: row;
		gap: 1rem;
		justify-content: center;
		align-items: center;

		button {
			border: none;
			background-color: transparent;
			padding: 0;
			transition-duration: 500ms;

			&:hover {
				scale: 1.2;
				transition-duration: 500ms;
			}
		}
	}

	&__share-content {
		display: flex;
		align-items: center;
		gap: 5px;

		img {
			width: 18px;
		}
	}
}
