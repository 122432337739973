.disabled-image {
    opacity: 0.4; /* Reduce the opacity to make it appear faded */
    filter: grayscale(100%); /* Apply grayscale to make it appear black and white */
}

.selected {
    background-color: gray;
}

.horizontal-line {
    position: relative;
 }
  
.horizontal-line::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid black;
}