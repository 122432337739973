.be-prepared {

    .ywh-page-hero {
        @media (max-width: 992px) {
            overflow: hidden;
        }
    }
    .ywh-section-style-1 {
        @media (max-width: 992px) {
            flex-direction:  column;
        }
        @media (min-width: 993px) {
            flex-direction:  row;
        }
    }

    .ywh-section-container {
        & .ywh-section__header-text--subheading {
            @media (max-width: 992px) {
                flex-direction: column;
            }
        }

        & .ywh-section--col-1 {
            @media (max-width: 992px) {
                padding: 0 1rem;
            } 
        }
    }
}