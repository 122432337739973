@use "../utils" as util;
@import "../utils";

.sections {
	margin: 3.5rem 0 3.5rem 0;
}

.section-image-separator {
	display: flex;
	justify-content: center;
	margin-top: 3rem;

	img {
		width: 70%;
	}
}

.section-row-2-cols {
	display: flex;
	gap: 4rem;

	.section-col {
		display: flex;
		flex: 1;
		align-items: center;

		&__text {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			justify-content: center;
		}

		p {
			@include util.ywh-h6-regular;
			align-self: center;
		}

		img {
			display: flex;
			width: 100%;
			object-fit: cover;
			object-position: center center;
			border-radius: 8px;
			align-self: stretch;
		}
	}
}

//1 Column Text Banner
.section-row-1-col {
	.banner {
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.88)),
			url("https://bucket.ywhdev.com/wl/?id=0V3OnSpt2DAEUP1Zp6PLUm5YgUNaVStD&fmode=open");
		border-radius: 8px;
		background-size: cover;
		background-position: center;
		padding: 5rem 0 5rem 0;

		&__text {
			width: 70%;
			text-align: center;
		}

		h4 {
			@include util.ywh-h4-medium;
			color: map-get($colors, "silverChalice");
		}
	}

	//1 Column Image Banner
	.section-col {
		&__image-banner {
			width: 100%;

			img {
				width: 100%;
				max-height: 450px;
				border-radius: 8px;
				object-fit: cover;
			}
		}
	}
}
