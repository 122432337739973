@use "../utils" as util;
@import "../utils";

.section-3-wrapper {
	margin-top: -85px !important;
}

.section-container {
	max-width: 1900px;
	margin: auto;

	.section-wrapper {
		background-color: map-get($colors, "mystic");
		margin-top: -60px;
	}

	.top-img-separator {
		img {
			max-height: 150px;
			margin: auto;
			width: 100%;
		}
	}

	.bottom-img-separator {
		margin-top: -2px;

		img {
			max-height: 150px;
			width: 100%;
		}
	}
}

.section-3 {
	&__header {
		margin-top: 0 !important;
	}

	.highlight {
		flex-direction: column;
		gap: 6rem;

		.icon-sets {
			flex-flow: row wrap;
			justify-content: space-around;
			align-items: flex-start;
			padding: 0 2rem 0 2rem;
			gap: 4rem;

			&__group {
				flex-direction: column;
				align-items: center;
				padding: 0;
				gap: 12px;
				width: 25%;
				flex: none;
				order: 0;
				align-self: stretch;
				flex-grow: 0;

				img {
					width: 120px;
				}

				h4 {
					@include util.ywh-h4-medium;
					text-align: center;
				}
			}
		}

		.image-group {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 0px;
			gap: 2rem;

			img {
				border-radius: 8px;
				flex: none;
				flex-grow: 1;
				max-height: 260px;
				object-fit: cover;
				object-position: center center;
				width: 100%;
			}
		}

		.section-cta {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 3rem;
			width: 70%;
			background-color: map-get($colors, "royalBlue");
			border-radius: 16px;
			margin: auto;
			padding: 4rem 8rem 4rem 8rem;

			h3 {
				@include util.ywh-h3-medium;
				color: map-get($colors, "white");
				text-align: center;
			}
		}
	}
}
