@use "../utils" as util;
@import "../utils";

.ywh-page-hero {
	background-size: cover;
	background-position: center;
}
.choosing-realtor-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=3BxMUDROlsHUYb4gyiadAM2xWIbtarz1&fmode=open");
}

.be-prepared-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=mFlCl2uoJM3QOlDQu28O8RJ5PRn2ynWf&fmode=open");
}

.closing-costs-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=pMGU0gMKd08bsIqDJ61EZJFOnjKBCout&fmode=open");
}

.pricing-your-home-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=ZQrBCiDki4q7jCpXhiMvJMg9rCoY2xef&fmode=open");
}

.marketing-your-home-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=fn5iy14kte65nRKogiqcA5Yqj6t1oFmu&fmode=open");
}

.showing-your-home-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=bRE69XKgprIKBNA2M3XnbW0pZ3ZoDTOS&fmode=open");
}

.nine-minute-drill-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=NeYSrF5XJYVo5TENhZPbLMdk4VdVfoci&fmode=open");
}

.sell-your-home-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=yBMPywNeaoqySQkXnjJcUqxdmhTAEzmj&fmode=open");
}

.custom-homes-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=ndcHEFnWiLswqNGroTbu2sgYT5wVGEcA&fmode=open");
}

.our-team-img {
	background-image: url("https://bucket.ywhdev.com/wl/?id=Ky5aLjSSF4uCWEwca2jmTYpP4y0czc2s&fmode=open");
}

.ywh-section-container {
	display: flex;
	flex-flow: column nowrap;
	gap: 5rem;
	padding: 6rem 0;
}

.ywh-section-container-no-top-padding {
	display: flex;
	flex-flow: column nowrap;
	gap: 5rem;
	padding-top: 0;
	padding-bottom: 6rem;
}

//Below CSS are generic layout and used by all ywh site inner pages.
//Create a new class for additional values.

.left-bordered-h6--align-left {
	display: flex;
	padding: 0rem 0rem 0rem 1rem;
	align-items: flex-start;
	border-left: 2px solid #cda07d;
	@include util.ywh-h6-bold;
}

.width-80-percent {
	width: 80%;
}

.width-70-percent {
	width: 70%;
}

.subheading-align-center {
	text-align: center;
}

.subheading-font-weight-regular {
	font-weight: 400 !important;
}

.body-font-weight-regular {
	font-weight: 400 !important;
}

.ywh-section-wrapper {
	display: flex;
	flex-flow: column nowrap;
	gap: 2rem;
}

.upsize-text {
	color: map-get($colors, "vistaBlue");
	font-family: Inter;
	font-weight: 700;

	&--large {
		font-size: 10rem;
		line-height: 7.5rem;
	}

	&--small {
		font-size: 6.25rem;
		line-height: 6.25rem;
	}
}

//Flex Options
.col-flex-min {
	flex: min-content;
}

.col-flex-1 {
	flex: 1;
}

.col-flex-2 {
	flex: 2;
}

.col-flex-basis-50-percent {
	flex-basis: 50%;
}

.col-flex-basis-45-percent {
	flex-basis: 45%;
}

.col-flex-basis-40-percent {
	flex-basis: 40%;
}

.col-flex-basis-35-percent {
	flex-basis: 35%;
}

.col-flex-basis-30-percent {
	flex-basis: 30%;
}

.col-flex-basis-25-percent {
	flex-basis: 25%;
}

.ywh-section-style-1 {
	width: 1200px;
	gap: 8rem;
	padding: 1rem 0;
}

.ywh-section-style-2 {
	flex-flow: column nowrap;
	width: 1200px;
	gap: 4rem;
	padding: 1rem 0;
}

.ywh-section-style-3 {
	flex-flow: column nowrap;
	width: 1200px;
	gap: 1rem;
	padding: 1rem 0;
}

.ywh-section-style-4 {
	flex-flow: column nowrap;
	width: 1200px;
	gap: 6rem;
	padding: 1rem 0;
}

.ywh-section-1900w {
	width: 1900px;
	margin: auto !important;
}

.section-footer {
	display: flex;
	padding: 5rem 0rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
	background: map-get($colors, "mystic");

	h2 {
		color: map-get($colors, "royalBule");
		text-align: center;

		/* ywh-h2-montserrat */
		font-size: 2.4375rem;
		font-family: Montserrat;
		font-weight: 700;
		line-height: 3.16875rem;
	}

	h3 {
		color: map-get($colors, "silverChalice");
		text-align: center;

		/* ywh-h3-montserrat */
		font-size: 1.875rem;
		font-family: Montserrat;
		font-weight: 700;
		line-height: 2.4375rem;
	}
}

.ywh-section {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	&--header-style-1 {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.5rem;
		width: 25rem;
		align-self: center;

		&__upsize-text {
			position: absolute;
			left: 0;
			color: map-get($colors, "lavender");
		}

		&__text {
			display: flex;
			align-items: center;
			gap: 1rem;
			position: relative;
			justify-content: center;
			width: 100%;
		}

		h2 {
			text-transform: uppercase;
			@include util.ywh-h2-bold;
			color: map-get($colors, "royalBlue");
			z-index: 2;
		}

		&__bottom-border {
			width: 100%;
			height: 0.5rem;
			border-radius: 10px;
			background: map-get($colors, "tumbleweed");
		}
	}

	&--header-style-2 {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.125rem;

		img {
			width: 2.625rem;
			height: 3.375rem;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4rem;
		flex: 1;

		&--body {
			display: flex;
			flex-flow: column nowrap;
			gap: 2rem;
		}

		h3 {
			text-align: center;
		}

		p {
			@include util.ywh-body;
			text-align: justify;
		}

		ul {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 1rem;
			width: 100%;
			padding-left: 0;

			li {
				display: flex;
				gap: 1rem;
				list-style-type: none;
				@include util.ywh-h6-bold;

				i {
					color: map-get($colors, "azure");
					font-size: 1.6rem;
					padding-top: 5px;
				}
			}
		}
	}

	&__text-col-1 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		flex: 1;
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		align-self: stretch;

		img {
			width: 8.125rem;
			height: 8.125rem;
		}

		&--style-regular {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 2rem;

			img {
				width: 8.125rem;
				height: 8.125rem;
			}

			h2 {
				color: map-get($colors, "royalBlue");
				text-align: center;
				@include util.ywh-h2-bold;
			}
		}
	}

	&__header-text {
		display: flex;
		flex-flow: column wrap;
		gap: 0.8rem;

		&--heading {
			display: flex;
			flex-flow: column nowrap;
			gap: 8px;

			h2 {
				color: map-get($colors, "royalBlue");
				text-align: center;
				@include util.ywh-h2-bold;
			}

			h3 {
				color: map-get($colors, "royalBlue");
				text-align: center;
				@include util.ywh-h3-regular;
				align-self: center;

				span {
					margin-top: 100px;
				}
			}

			h4 {
				@include util.ywh-h4-bold;
				text-align: center;
			}
		}

		&--subheading {
			display: flex;
			align-items: center;
			gap: 1rem;
			align-self: stretch;
			justify-content: center;

			img {
				width: 3.75rem;
				height: 3.75rem;
			}

			h4 {
				text-align: center;
				@include util.ywh-h4-semibold;
			}

			h3 {
				//color: map-get($colors, "royalBlue");
				text-align: center;
				align-self: center;
			}
		}
	}

	&__image {
		display: flex;
		align-items: flex-start;
		flex: 1;

		img {
			width: 100%;
			border-radius: 16px;
		}

		.shadow-left {
			box-shadow: -16px 8px 48px 0px rgba(0, 0, 0, 0.25);
		}

		.shadow-right {
			box-shadow: 16px 8px 48px 0px rgba(0, 0, 0, 0.25);
		}
	}

	&--col-1 {
		display: flex;
		padding: 0 10rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2.5rem;
		align-self: stretch;
		text-align: center;

		&__heading {
			display: flex;
			gap: 8px;
			align-items: center;

			img {
				width: 6rem;
				height: 5.57144rem;
			}
		}

		&__subheading {
			h4 {
				@include util.ywh-h4-bold;
			}
		}
	}

	h3 {
		@include util.ywh-h3-bold;
	}

	&--style-2 {
		display: flex;
		width: 1200px;
		justify-content: center;
		align-items: center;
		gap: 8rem;
		padding: 1rem 0;
		margin: auto;
	}

	&--col-2 {
		display: flex;
		align-items: flex-start;
		gap: 4rem 8rem;
		margin-top: 4rem;
		flex-flow: row wrap;

		&--col-1 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 1rem;
			box-shadow: 8px 8px 28px 0px rgba(0, 0, 0, 0.25);
			padding: 3rem 4rem;
			border-radius: 16px;
			width: 100%;
		}

		&__header {
			display: flex;
			align-items: center;
			gap: 1rem;
			align-self: stretch;

			i {
				font-size: 4rem;
				color: map-get($colors, "azure");
			}

			h4 {
				@include util.ywh-h4-bold;
			}
		}
	}

	&--col-3 {
		display: flex;
		align-items: flex-start;
		gap: 1.5rem;
		flex-flow: row wrap;

		&--col-1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-self: stretch;
			border: 1px solid #cda07d;
			padding: 2rem 1.5rem;
			gap: 1rem;
			text-align: center;
		}
	}

	&__section-footer-1 {
		display: flex;
		padding: 0rem 0rem 0rem 4rem;
		align-items: flex-start;
		gap: 5rem;
		align-self: stretch;
		border-radius: 16px;
		background: map-get($colors, "alto");

		&__text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 3rem;
			flex: 1;
			align-self: stretch;

			h2 {
				@include util.ywh-h2-bold;
				color: map-get($colors, "royalBlue");
				display: flex;
				flex-direction: column;
				align-self: stretch;
			}

			&--body {
				ul {
					display: flex;
					flex-flow: column nowrap;
					gap: 2rem;
					padding: 0;

					li {
						display: flex;
						align-items: flex-start;
						gap: 1rem;
						align-self: stretch;

						i {
							color: map-get($colors, "azure");
							font-size: 2rem;
							padding-top: 5px;
						}
					}
				}
			}
		}

		&__image {
			width: 100%;
			flex: 1;
			align-self: stretch;

			img {
				width: 100%;
				border-radius: 0 16px 16px 0;
			}
		}
	}

	&__banner {
		&--small {
			display: flex;
			padding: 2rem 0rem;
			flex-direction: column;
			align-items: flex-start;
			gap: 2.5rem;
			align-self: stretch;
			border-radius: 16px;
			background: map-get($colors, "azure");
			width: 100%;

			h3 {
				color: map-get($colors, "white");
				align-self: center;
			}
		}
	}

	&__cards {
		display: flex;
		padding: 0rem 2.5rem;
		justify-content: center;
		align-items: center;
		gap: 7.5rem;
		align-self: stretch;

		&--style-1 {
			display: flex;
			position: relative;
			align-items: center;

			img {
				display: flex;
				width: 50%;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 4rem;
				flex: 1 0 0;
				border-radius: 16px;
				box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.25);
			}

			h3 {
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba(31, 31, 31, 0.75);
				position: absolute;
				width: 100%;
				color: map-get($colors, "white");
				height: 80px;
			}
		}

		&--style-2 {
			display: flex;
			padding: 2.5rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1.5rem;
			border-radius: 16px;
			background: map-get($colors, "white");
			box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.25);
			width: 270px;
			height: 250px;

			img {
				width: 4.375rem;
				height: 4.375rem;
			}

			h3 {
				text-align: center;
			}
		}
	}

	&__cards-v2 {
		&__wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: flex-start;
			align-self: stretch;
			flex-wrap: wrap;
		}

		&__headings {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 0.5rem;
			color: map-get($colors, "royalBlue");
			font-style: normal;
			font-weight: 700;
		}

		&--heading-1 {
			font-size: 3.75rem;
			font-family: Montserrat;
			line-height: 3.75rem;
		}

		&--heading-1-smaller {
			font-size: 3rem;
			line-height: 3rem;
		}

		&--heading-2 {
			color: var(--ywh-raisin-black, #2f2f2f);
			font-size: 2.5rem;
			font-family: Montserrat;
			font-style: normal;
			font-weight: 600;
			line-height: 2.4375rem;
		}

		&--styles {
			display: flex;
			width: 34.375rem;
			height: 21.875rem;
			padding: 3.75rem 5rem;
			justify-content: center;
			align-items: center;
			gap: 1.5rem;
		}

		&--style-1 {
			border-radius: 1rem 0rem;
			background: #fff;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);

			img {
				width: 6.25rem;
				flex-shrink: 0;
			}
		}

		&--style-2 {
			border-radius: 0rem 1rem 0rem 0rem;
			border-top: 1px solid map-get($colors, "mercury");
			border-right: 2px solid map-get($colors, "mercury");

			img {
				width: 6.25rem;
				flex-shrink: 0;
			}
		}

		&--style-3 {
			border-radius: 0rem 0rem 0rem 1rem;
			border-bottom: 2px solid var(--ywh-mercury, #e8e8e8);
			border-left: 2px solid var(--ywh-mercury, #e8e8e8);

			img {
				width: 4.5rem;
				flex-shrink: 0;
			}
		}

		&--style-4 {
			border-radius: 1rem 0rem;
			background: #fff;
			box-shadow: -2px 0px 12px 0px rgba(0, 0, 0, 0.15);

			img {
				width: 6.25rem;
				flex-shrink: 0;
			}
		}
	}

	&__gallery {
		display: flex;
		gap: 2rem;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;

		img {
			cursor: pointer;
		}

		&--overlay-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row nowrap;
			gap: 1rem;
			height: 100%;

			button {
				border: none;
				background: transparent;
				color: map-get($colors, "vistaBlue");

				i {
					font-size: 2.5rem;
					padding: 0 1rem;
				}
			}
		}

		&__overlay {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.9);
			z-index: 9999;

			img {
				max-width: 100% !important;
				border-radius: 16px !important;
			}
		}

		&--style-1 {
			display: flex;
			flex-flow: column nowrap;
			width: 23rem;
			justify-content: center;

			img {
				width: 100%;
				border-radius: 1rem 1rem 0 0;
			}

			h5 {
				display: flex;
				width: 100%;
				padding: 1.5rem 0rem;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				border-radius: 0rem 0rem 1rem 1rem;
				background: map-get($colors, "royalBlue");
				color: map-get($colors, "white");
			}
		}
	}

	&__form {
		display: flex;
		align-items: center;
		gap: 7.5rem;
		align-self: stretch;

		&--text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 3rem;
			flex: 1 0 0;
			text-align: center;

			h3 {
				color: map-get($colors, "royalBlue");
			}

			p {
				font-size: 1.1rem !important;
			}
		}

		&--wrapper {
			display: flex;
			padding: 3rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 3rem;
			border-radius: 16px;
			border: 2px solid map-get($colors, "tumbleweed");
			flex-basis: 60%;
		}

		&--content {
			display: flex;
			align-items: flex-start;
			gap: 1rem;
			align-self: stretch;
			flex-flow: row wrap;

			label {
				font-size: 1.1rem;
			}

			input {
				height: 3.25rem;
				align-self: stretch;
				border-radius: 8px;
				background: map-get($colors, "mercury");
				border: none;
				font-size: 1rem;
				padding: 0 1rem;
				color: map-get($colors, "dimGray");
			}
		}

		&--field-group {
			display: flex;
			flex-flow: column nowrap;
			flex-basis: 48.7%;

			&--full-width {
				width: 100%;
				font-size: 1rem;
				padding: 1rem;
				color: map-get($colors, "dimGray");
				border-radius: 8px;
				background: map-get($colors, "mercury");
				border: none;
			}
		}

		&--button {
			display: flex;
			padding: 1.5rem 0rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-self: stretch;
			border-radius: 16px;
			background: map-get($colors, "royalBlue");
			@include util.ywh-button-large;
			color: map-get($colors, "white");
			cursor: pointer;

			&:hover {
				background: map-get($colors, "azure");
			}
		}
	}

	&__col-2-full {
		display: flex;
		align-items: center;
		align-self: stretch;
		flex-flow: row nowrap;

		&--left-col {
			display: flex;
			padding-top: 30px;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			flex-basis: 45%;

			img {
				width: 100%;
			}
		}

		&--right-col {
			display: flex;
			padding: 0rem 10rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 4rem;
			flex-basis: 55%;
			align-self: stretch;
			background: var(--ywh-raisin-black, #2f2f2f);

			img {
				width: 8.125rem;
				height: 8.125rem;
			}

			h2 {
				font-weight: 700 !important;
			}

			h2,
			h3 {
				color: map-get($colors, "white");
				text-align: center;
			}
		}
	}
}

.ywh-section-full-width {
	width: 100%;

	&__cta {
		display: flex;
		justify-content: center;
		align-items: center;
		background: map-get($colors, "nero");
		padding: 4rem 0;

		&--style-1 {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 46rem;

			img {
				width: 7.5rem;
				height: 7.15906rem;
			}

			h2 {
				color: map-get($colors, "white");
			}
		}
	}
}

.ywh-timeline {
	display: flex;
	padding: 0 5.6875rem 7.5rem 5.6875rem;
	flex-flow: column nowrap;
	align-items: center;

	&--style-1 {
		z-index: 10;
	}

	&--style-2,
	&--style-3,
	&--style-4,
	&--style-5,
	&--style-6,
	&--style-7 {
		margin-top: -4.5rem;
	}

	&--style-2 {
		z-index: 9;
	}

	&--style-3 {
		z-index: 8;
	}

	&--style-4 {
		z-index: 7;
	}

	&--style-5 {
		z-index: 6;
	}

	&--style-6 {
		z-index: 5;
	}

	&__contents {
		display: flex;
		width: 65rem;
		padding: 5rem 4rem;
		align-items: center;
		gap: 4rem;
		box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.25);

		&--style-1 {
			border-radius: 1rem 1rem 0 0;
			background: map-get($colors, "lavender");
		}

		&--style-2 {
			background: map-get($colors, "azure");

			h2,
			h3 {
				color: map-get($colors, "white") !important;
			}
		}

		&--style-3 {
			background: map-get($colors, "lavender");
		}

		&--style-4 {
			border-radius: 0 0 1rem 1rem;
			background: map-get($colors, "lavender");
		}

		img {
			width: 6.25rem;
			flex-shrink: 0;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
		flex: 1 0 0;
		align-self: stretch;

		h2 {
			color: map-get($colors, "royalBlue");
			@include util.ywh-h2-bold;
		}

		h3 {
			@include util.ywh-h3-regular;
		}
	}

	&__accent {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&--style-1 {
			padding-left: 100px;
			align-items: flex-start;
		}

		&--style-2 {
			padding-right: 100px;
			align-items: flex-end;
		}

		img {
			width: 9.6875rem;
			height: 4.5rem;
		}
	}
}
