@use "../utils/" as util;
@import "../utils";

.section-header {
	position: relative;
	font-weight: bold;
	margin-top: 6rem;
	margin-bottom: 6rem;
	width: 100%;

	&__title {
		position: relative;
		font-size: 3.2rem;
		z-index: 2;
	}

	&__title-sm {
		position: relative;
		font-size: 2.5rem;
		z-index: 2;
	}

	&__highlight {
		position: relative;
		font-size: 6rem;
		font-weight: bold;
		color: rgb(220, 231, 249, 0.3);
		margin-top: -120px;
		z-index: 1;
		line-height: 1.3;
	}

	&__hr {
		width: 100%;
		height: 4px;
		background: map-get($colors, "tumbleweed");
		border-radius: 10px;
	}
}
