@use "../utils" as util;
@import "../utils";

.community-home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5rem 2rem;
	gap: 64px;
	isolation: isolate;
	max-width: 1900px;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		#edf2f4 44.79%
	);

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 0;
		gap: 16px;
		width: 100%;
		position: relative;

		.header-icon {
			width: 280px;
		}

		h2 {
			@include util.ywh-h2-bold;
			color: map-get($colors, "royalBlue");
			margin-top: -80px;
		}

		h3 {
			max-width: 950px;
		}

		.header-description {
			@include util.ywh-h3-regular;
		}
	}

	&__wrapper {
		display: flex;
		flex-flow: row wrap;
		gap: 2rem;
		justify-content: center;
		padding: 5rem;
		filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
	}

	&__list {
		img {
			width: 300px;
			border-radius: 8px 8px 0 0;
		}

		img:hover {
			filter: opacity(50%);
			cursor: pointer;
		}

		h3 {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 20px 0;
			width: 300px;
			background: map-get($colors, "white");
			border-radius: 0px 0px 8px 8px;
		}

		a {
			text-decoration: none;
			color: map-get($colors, "nero");
		}
	}

	&__name {
		@include util.ywh-h4-bold;
		@include util.font-family("montserrat");
	}

	#overlay-container {
		display: none;
		position: fixed;
		background-color: rgba(0, 0, 0, 0.9);
		width: 100%;
		height: 100%;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.overlay-wrapper {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);

			.community-home-overlay {
				display: flex;
				flex-direction: row;
				width: 1200px;
				background: map-get($colors, "athensGray");
				border-radius: 16px;

				img {
					width: 400px;
					border-radius: 16px 0px 0px 16px;
					object-fit: cover;
				}

				&__text {
					display: flex;
					flex-flow: column;
					align-items: center;
					padding: 3rem;
				}

				header {
					margin-bottom: 4rem;
				}

				.heading {
					@include util.ywh-h3-bold;

					&__sub {
						@include util.font-size("cardText");
					}
				}

				&__lists {
					ul {
						display: flex;
						flex-flow: row wrap;
						justify-content: center;
						column-gap: 4rem;
					}

					ul li {
						@include util.font-size("cardText");
						@include util.font-weight("bold");
						width: 150px;
					}
				}
			}
		}
	}
}
