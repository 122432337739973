
.scrollable-div {
    width: 500px;
    max-height: 300px;
    overflow-y: scroll;
}

.scrollable-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.form-group {
    width: 500px;
}

.remove-button {
    height: 32px;
    font-size: 12px;
    padding: 0 8px 0 8px;
    margin-left: 36px;
    background-color: red;
}

.add-button {
    height: 32px;
    font-size: 12px;
    padding: 0 8px 0 8px;
    margin-left: 36px;
}