@use "../utils" as util;
@import "../utils";

.agent {
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 3rem;

	&__col {
		display: flex;
		width: 47%;

		&--img {
			display: flex;
		}

		&--img img {
			border-radius: 8px;
			object-fit: cover;
		}

		img {
			width: 100%;
		}

		&--form {
			display: flex;
			padding: 2rem 3rem;
			border: 1px solid map-get($colors, "tumbleweed");
			border-radius: 8px;

			.button {
				display: flex;
				justify-content: center;
			}
		}
	}
	&__details {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		justify-content: flex-start;

		h3 {
			@include util.ywh-h3-bold;
		}

		ul {
			display: flex;
			gap: 3rem;
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: 1.15rem;
		}

		p {
			font-size: 1.15rem !important;
		}
	}

	&__hr {
		width: 100%;
		height: 4px;
		background: map-get($colors, "alto");
		border-radius: 10px;
	}
}
