@use "../utils" as util;
@import "../utils";

.btn-border {
	@include util.ywh-button-regular;
	height: fit-content;
	color: map-get($colors, "seaSalt");
	border: 1px solid map-get($colors, "seaSalt");
	border-radius: 18px;
	background-color: transparent;
	padding: 0.5rem 1rem 0.5rem 1rem;

	&:hover {
		background-color: map-get($colors, "tumbleweed");
		border: 1px solid map-get($colors, "tumbleweed");
		color: map-get($colors, "white");
	}
}

.button {
	&--cta {
		padding: 1rem 4rem;
		background: map-get($colors, "azure");
		border: none;
		border-radius: 50px;
		text-align: center;
		text-transform: uppercase;
		@include util.ywh-h4-bold;
		color: map-get($colors, "white");
		transition-duration: 500ms;

		&:hover {
			border-radius: 10px;
			background: map-get($colors, "vistaBlue");
			transition-duration: 500ms;
		}
	}

	&--form {
		padding: 0.8rem 4rem;
		background: map-get($colors, "royalBlue");
		border: none;
		border-radius: 50px;
		text-align: center;
		text-transform: uppercase;
		@include util.ywh-h6-bold;
		color: map-get($colors, "white");
		transition-duration: 500ms;

		&:hover {
			border-radius: 10px;
			background: map-get($colors, "azure");
			transition-duration: 500ms;
		}
	}
}

.button-pos-center {
	text-align: center;
}
