@use "ywh-brand" as ywh;

//Font Sets CUSTOM - Font Family, Font Size, Font Weight , Line Height
@mixin font-set--fm-fs-fw-lh(
	$font-family,
	$font-size,
	$font-weight,
	$line-height
) {
	@include ywh.font-family($font-family);
	@include ywh.font-size($font-size);
	@include ywh.font-weight($font-weight);
	@include ywh.line-height($line-height);
	margin: 0;
}

//Font Sets CUSTOM - Font Size, Font Weight , Line Height
@mixin font-set--fs-fw-lh($font-size, $font-weight, $line-height) {
	@include ywh.font-size($font-size);
	@include ywh.font-weight($font-weight);
	@include ywh.line-height($line-height);
	margin: 0;
}

//Font Sets CUSTOM - Font Weight , Line Height
@mixin font-set--fw-lh($font-weight, $line-height) {
	// @include ywh.font-weight($font-weight);
	@include ywh.line-height($line-height);
	margin: 0;
}
