@use "../utils" as util;
@import "../utils";

.homepage {
	&__hero {
		display: flex;
		max-width: 1920px;
		height: 750px;
		background: url("https://bucket.ywhdev.com/wl/?id=QFm0fbiYzkyExIwvuoWKie9r5v3JZQ9d&fmode=open");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		margin: auto;
		padding-bottom: 80px;
		justify-content: center;
		align-items: flex-end;

		.ywh-search-bar {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			align-items: center;
			filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.5));

			&__tab-content {
				display: none;
				transition: all 0.3s ease;

				&.active {
					display: flex;
					justify-content: space-between;
					gap: 16px;
					align-items: center;
					width: 100%;
					height: 110px;
					background: map-get($colors, "seaSalt");
					border-radius: 16px;
					transition: opacity 0.5s ease-in-out;
				}
			}

			&__tabs {
				display: flex;
				flex-direction: row;
				padding: 0;
				gap: 4px;
				height: 51px;
				@include util.ywh-button-regular;
				font-weight: 500;
			}

			a {
				text-decoration: none;
				background: map-get($colors, "azure");
				color: map-get($colors, "white");
				border-radius: 16px 16px 0 0;
				padding: 16px 20px;
				height: 51px;
				min-width: 200px;
				text-align: center;

				&:hover,
				&:focus {
					background: map-get($colors, "seaSalt");
					color: map-get($colors, "royalBlue");
				}
			}

			&__form {
				display: flex;
				flex: 1;
				width: 100%;
			}

			&__search-field {
				display: flex;
				justify-content: space-between;
				gap: 16px;
				align-items: center;
				padding: 0 30px;
				width: 1000px;
				height: 110px;
				background: map-get($colors, "seaSalt");
				border-radius: 16px;
			}

			&__placeholder {
				display: flex;
				align-items: center;
				width: 100%;
				height: 57px;
				background: map-get($colors, "mercury");
				border-radius: 8px;
				@include util.ywh-caption;
				color: map-get($colors, "silverChalice");
				padding-left: 30px;
				border: none;
			}

			&__button {
				display: flex;
				align-items: center;
				gap: 1rem;
				height: 57px;
				background: map-get($colors, "royalBlue");
				border-radius: 16px;
				border: none;
				color: map-get($colors, "white");
				padding: 0 30px;
				@include util.ywh-button-regular;

				i {
					font-size: 1.6rem;
				}

				img {
					width: 25px;
					height: 25px;
				}

				&:hover {
					background: map-get($colors, "azure");
				}
			}
		}
	}

	&__body {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}

	&__sec-1 {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: 120px 80px;
		gap: 120px;
		width: 1900px;
		text-align: center;
		margin: auto;

		&--left-col {
			display: flex;
			flex-flow: column wrap;
			align-items: center;
			gap: 64px;
			flex: 1;

			h5 {
				@include util.ywh-h5-regular;
			}
		}

		&--right-col {
			display: flex;
			gap: 32px;
			flex: 1;

			img {
				box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4);
				border-radius: 16px;
				width: 48%;
			}
		}
	}

	&__section {
		position: relative;
		display: flex;
		padding: 5rem 0rem;
		align-items: flex-start;
		justify-content: center;
		align-content: center;
		max-width: 1920px;
		margin: auto;

		&--subheading {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.5rem;
			align-self: stretch;
			padding-bottom: 0.3rem;
			border-bottom: 0.125rem solid #cda07d;

			p {
				@include util.ywh-body;
			}
		}

		&--border-radius-right {
			border-radius: 0 32px 32px 0;
		}

		&--border-radius-left {
			border-radius: 32px 0 0 32px;
		}

		&--indent-left {
			margin-left: -130px;
		}

		&--bigger-padding-on-left {
			padding: 4rem 10rem 4rem 15rem;
		}

		&--bigger-padding-on-right {
			padding: 4rem 15rem 4rem 10rem;
		}

		&--img {
			display: flex;
			padding: 5rem 0rem;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			align-self: stretch;
			z-index: 2;

			img {
				max-height: 37.5rem;
				align-self: stretch;
				box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.4);
				width: 100%;
			}
		}

		&__details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 2rem;
			flex: 1 0 0;
			align-self: stretch;
			background: map-get($colors, "mystic");
			z-index: 1;
		}

		h6 {
			display: flex;
			width: 44.375rem;
			flex-direction: column;
			@include util.ywh-h6-regular;
		}
	}

	&__sec-4 {
		display: flex;
		padding: 5rem 0rem 10rem 0rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10rem;
		align-self: stretch;
		text-align: center;

		&__contents {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 75rem;
		}

		&__tabs {
			display: flex;
			align-items: flex-start;
			gap: 0.5rem;
			align-self: stretch;
			margin-top: 7rem;
		}

		&__tab {
			padding: 1rem 1.5rem !important;
			justify-content: center;
			gap: 1rem !important;
			border-radius: 16px 16px 0 0 !important;

			&--active {
				background-color: map-get($colors, "azure") !important;
			}

			.sec-4-tab-img-1 {
				width: 2rem;
				height: 1.91306rem;
			}

			.sec-4-tab-img-2 {
				width: 1.25rem;
				height: 2.10794rem;
			}

			&:active {
				background-color: map-get($colors, "azure");
			}
		}

		&__tab-content-wrapper {
			display: flex;
			align-items: flex-start;
			align-self: stretch;
		}

		&__tab-content-container {
			display: flex;
			align-items: flex-start;
			flex: 1;
			box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.4);
			border-radius: 0 0 16px 16px;
			margin-top: -1px;

			img {
				display: flex;
				width: 29.8125rem;
				align-items: flex-start;
				gap: 0.5rem;
				align-self: stretch;
				border-radius: 0 0 0 16px;
			}
		}

		&__home-valuation-content {
			display: flex;
			padding: 4rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			flex: 1 0 0;
			align-self: stretch;
		}

		&__tab-contents {
			display: flex;
			padding: 4rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			flex: 1;
			align-self: stretch;
			border-radius: 0px 16px 16px 0px;
			border-left: 8px solid #7ba4e9;
			background: map-get($colors, "mystic");

			&--header {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 5rem;
			}

			&--subheading {
				display: flex;
				width: 90%;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 0.5rem;

				p {
					text-align: center;
					@include util.ywh-body;
					padding-bottom: 1rem;
					border-bottom: 0.125rem solid #cda07d;
				}
			}

			&--heading {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				align-self: stretch;

				img {
					width: 6rem;
					align-self: center;
				}

				h3 {
					@include util.ywh-h3-bold;
				}
			}

			&--body {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 2.5rem;
				align-self: stretch;

				p {
					@include util.ywh-body;
					text-align: center;
				}
			}
		}
	}

	button {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 40px;
		gap: 16px;
		background: map-get($colors, "royalBlue");
		border-radius: 16px;
		color: map-get($colors, "white");
		@include util.ywh-body;
		border: none;

		&:hover {
			background: map-get($colors, "azure");
		}

		i {
			font-size: 1.8rem;
		}
	}
}
