.content-body {
    margin-top: 0px;

    & .city-hero {
        & img {
            @media (max-width: 992px) {
                height: 375px;
            }
        }
        & h1 {
            @media (max-width: 992px) {
                font-size: 2.5rem;
                bottom: -42px;
                top: unset;
                width: 100%;
                padding: 1rem;
                border-radius: unset;
            }
        }
    }

    & .city__details {
        @media (max-width: 992px) {
            padding: 0px;
        }
    }

    & .city__list ul {
        width: 100%;
        max-width: 1900px;
        & li {
            background-color: #5284E0;
            @media (max-width: 992px) {
                flex: 1 1 calc(50% - 2rem);
                font-size: 1rem;
                display: flex;
                justify-content: center;
            }
            & a {
                color: white!important;
            }
        }
    } 
}