@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

// Font Sizes
// $font-size: (
// 	"h1": 3.71rem,
// 	"h2": 2.61rem,
// 	"h3": 1.92rem,
// 	"h4": 1.61rem,
// 	"h5": 1.53rem,
// 	"h6": 1.34rem,
// 	"body": 1.28rem,
// 	"caption": 1.07rem,
// 	"endnote": 0.94rem,
// 	"footnote": 0.84rem,
// 	"buttonRegular": 1.07rem,
// 	"buttonLarge": 1.19rem,
// 	"cardText": 1.13rem,
// 	"navText": 1.07rem,
// );

$font-size: (
	"h1": 3.7rem,
	"h2": 2.4rem,
	"h3": 1.7rem,
	"h4": 1.5rem,
	"h5": 1.4rem,
	"h6": 1.3rem,
	"body": 1.3rem,
	"caption": 1.07rem,
	"endnote": 0.94rem,
	"footnote": 0.84rem,
	"buttonRegular": 1.07rem,
	"buttonLarge": 1.19rem,
	"cardText": 1.13rem,
	"navText": 1.07rem,
);

@mixin font-size($size) {
	font-size: map-get($font-size, $size);
}

// Line Height
$line-height: (
	"2": 2,
	"1.62": 1.62,
	"1.5": 1.5,
	"1.3": 1.3,
	"1": 1,
);

@mixin line-height($value) {
	line-height: map-get($line-height, $value);
}

// Font Family
$font-family: (
	"montserrat": "Montserrat",
	"playfair": "Playfair Display",
);

@mixin font-family($type) {
	font-family: map-get($font-family, $type);
}

// Font Weight
$font-weight: (
	"regular": 400,
	"medium": 500,
	"semi-bold": 600,
	"bold": 700,
);

@mixin font-weight($weight) {
	font-weight: map-get($font-weight, $weight);
}

// Colors
$colors: (
	"seaSalt": #f9f9f9,
	"athensGray": #f4f4f6,
	"mystic": #edf2f4,
	"mercury": #e8e8e8,
	"alto": #d8d8d8,
	"silverChalice": #a8a8a8,
	"lavender": #dce7f9,
	"vistaBlue": #7ba4e9,
	"unBlue": #6292e4,
	"azure": #5284e0,
	"kashmirBlue": #52648e,
	"conflowerBlue": #344472,
	"royalBlue": #04246b,
	"tumbleweed": #cda07d,
	"tan": #dac5a5,
	"gray": #787878,
	"dimGray": #686868,
	"davysGray": #525252,
	"mineShaft": #212121,
	"raisinBlack": #2f2f2f,
	"nero": #1f1f1f,
	"night": #0a0a0a,
	"white": #ffffff,
	"black": #000000,
);

@mixin color($color) {
	font-size: map-get($colors, $color);
}
