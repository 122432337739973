.state {
    header {
        padding: 1rem;
        @media (min-width: 993px) {
            padding: 0px;
        }
    }
}

.state__name {
    color: black !important;
}

.state__wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;

    & .state__list {
        flex: 0 0 calc(50% - 2rem);
        & img {
            width: 100%;
            margin-bottom: 20px;
        }

        & h3 {
            text-align: center;
        }

        @media (min-width: 993px) {
            flex-basis: calc(25% - 2rem);
        }
        
        @media (min-width: 1600px) {
            flex-basis: calc(20% - 2rem);
        }
    }
}