@use "../utils" as util;
@import "../utils";

.community {
	flex-flow: row wrap;
	justify-content: center;
	gap: 5rem;

	.card {
		flex-direction: column;
		align-items: flex-start;
		padding: 40px;
		gap: 32px;
		width: 45%;
		border: 2px solid #cda07d;
		border-radius: 8px;
		align-self: stretch;
		flex-grow: 0;

		&__header {
			flex-direction: column;
			gap: 8px;
			width: 100%;

			header {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;

				img {
					width: 96px;
				}

				h5 {
					@include util.ywh-h5-bold;
				}
			}

			.hr {
				width: 100%;
				height: 4px;
				background: map-get($colors, "vistaBlue");
				border-radius: 10px;
			}
		}

		&__text-wrapper {
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			flex-grow: 1;

			footer {
				@include util.ywh-caption;
				font-style: italic;
				color: map-get($colors, "davysGray");
			}
		}
	}
}
