.homepage__hero {
  height: 600px;
  width: 100%;
  max-width: 100%;

  @media (min-width: 993px) {
    height: 750px;
  }

  .ywh-search-bar__tab-content {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
}

.homepage__container {
  overflow: hidden;
}

.ywh-search-bar__tab-content {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.search-bar {
  display: flex;
  flex-direction: column;
  margin: 0;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  min-width: 300px;
  max-width: 700px;
  width: calc(100% - 32px);

  @media (min-width: 768px) {
    width: 100%;
  }
  

  @media (min-width: 993px) {
    margin: 0px auto;
    max-width: 1000px;
    box-shadow: none;
    min-width: 950px;
    padding: 8px 16px;
  }

  .search-bar__tabs {
    padding: 0;
    gap: 0px;
    display: flex;
    max-width: 100%;

    @media (min-width: 993px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      margin: 0;
      max-width: 828px;
      margin: 0 auto;
    }
  }
  a.active,
  a:hover,
  a:hover span,
  a.active span {
    color: #04246b !important;
    font-weight: 500;
  }

  a.active {
    background-color: #f9f9f9;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5284e0;
    color: #ffffff;
    padding: 16px 8px;
    height: 51px;
    min-width: auto;
    width: 100%;
    border-radius: unset;
    text-align: right;

    &:last-child {
      border-top-right-radius: 16px;
    }

    &:first-child {
      border-top-left-radius: 16px;
    }

    @media (min-width: 993px) {
      padding: 16px 20px;
      min-width: 200px;
      font-size: 100%;
      border-radius: 16px 16px 0 0;
      text-align: center;
    }

    & span {
      color: #ffffff;
      font-size: 16px;

      @media (max-width: 767px) {
        font-size: 50%;
      }
    }
  }
  .search-bar__search-field {
    width: 100%;
    height: auto;
    padding: 1rem;
    border-radius: 0 0 16px 16px;
    background-color: #f9f9f9;

    @media (min-width: 768px) {
      padding: 2rem;
    }
    @media (min-width: 993px) {
      border-radius: 16px;
      // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
    }

    .ywh-search-bar__form {
      display: flex;
      align-self: stretch;

      @media (min-width: 993px) {
        width: 285px;
        flex: 1 0 0;
      }
    }

    .search-bar__button {
      background: #04246b;
      border-radius: 16px;
      border: none;
      color: #ffffff;
      padding: 12px 32px;
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;

      @media (min-width: 993px) {
        width: 260px;
        justify-content: center;
        border-radius: 50px;
      }
    }
  }

  .ywh-search-bar__tab-content {
    flex-direction: column;
    width: 100%;
    align-items: center;
    @media (min-width: 993px) {
      flex-direction: row;
    }
  }
}

#input-search-container {
  position: relative;
  width: 100%;

  & input:focus {
    outline: none;
    border: none;
  }

  & .search-bar__placeholder {
    width: 100%;
    background-color: #e8e8e8;
    border: none;
    font-size: 16px;
    line-height: 24px;
    border-radius: 16px;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    @media (min-width: 993px) {
      flex: 1 0 0;
      border-radius: 8px;
    }
  }

  & #input-typeahead-container {
    background: white;
    max-height: 300px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    border: 1px solid #eee;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: left;

    & ul {
      padding: 0;
      margin: 0;
      & li {
        padding: 5px 15px;
        & > ul li {
          padding: 5px 15px;
          cursor: pointer;

          &:hover {
            background-color: #04246b;
            color: white;
          }
        }
      }

      &.vm-list {
        & li {
          padding: 5px 15px;
          cursor: pointer;

          &:hover {
            background-color: #04246b;
            color: white;
          }
        }
      }
    }
  }
}

.homepage__section {
  padding: 2rem 0px;
  @media (min-width: 993px) {
    padding: 5rem 0px;
  }
  & h6,
  & .h6 {
    width: 100%;
    @media (min-width: 993px) {
      width: 44.375rem;
    }
  }
}

.ywh-inline-heading {
  flex-direction: column;
  @media (min-width: 993px) {
    flex-direction: row;
  }
}

.homepage__sec-1 {
  width: 100%;
  max-width: 1900px;
  padding: 1rem;
  flex-direction: column;

  @media (min-width: 1200px) {
    padding: 120px 80px;
    flex-direction: row;
  }

  & .homepage__sec-1--right-col {
    flex-direction: column;
    @media (min-width: 993px) {
      flex-direction: row;
    }
    & img {
      width: 70%;
      margin: auto;
      @media (min-width: 993px) {
        width: 48%;
      }
    }
  }
}

.homepage__sec-2 {
  width: 100%;
  max-width: 1900px;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  & .homepage__section--img {
    order: 1;
    & .homepage__section--border-radius-right {
      order: unset;
      border-radius: unset;
      @media (min-width: 993px) {
        flex-direction: row;
        border-radius: 0 32px 32px 0;
      }
    }
  }

  & .homepage__section__details {
    order: 2;
    padding: 4rem;
    margin-left: 0px;

    @media (min-width: 993px) {
      padding: 4rem 10rem 4rem 15rem;
      margin-left: -130px;
    }

    button {
      align-self: center;
      @media (min-width: 993px) {
        align-self: unset;
      }
    }
  }
}

.homepage__sec-3 {
  width: 100%;
  max-width: 1900px;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
    & .homepage__section__details {
      order: 1;
    }

    & .homepage__section--img {
      order: 2;
    }
  }

  & .homepage__section__details {
    padding: 4rem;
    @media (min-width: 993px) {
      padding: 4rem 15rem 4rem 10rem;
    }

    button {
      align-self: center;
      @media (min-width: 993px) {
        align-self: unset;
      }
    }
  }
}
.homepage__sec-4 {
  padding-bottom: 0px;

  @media (min-width: 993px) {
    padding-bottom: 10rem;
  }

  .homepage__sec-4__heading {
    width: 100%;
    @media (min-width: 993px) {
      width: auto;
    }
  }
  & .homepage__sec-4__contents {
    width: 100%;
    @media (min-width: 993px) {
      width: calc(100% - 2rem);
      margin: 0 auto;
      max-width: 1200px;
    }

    & .homepage__sec-4__tabs {
      align-items: end;
    }
  }
  & .homepage__sec-4__tab {
    font-size: 80%;
    @media (min-width: 993px) {
      font-size: 100%;
    }

    & .sec-4-tab-img-2 {
      @media (max-width: 992px) {
        width: 18px;
        height: auto;
      }
    }
  }
  & .homepage__sec-4__tab-contents {
    padding: 1rem;
    @media (min-width: 993px) {
      padding: 4rem;
    }
  }

  & .homepage__sec-4__tab-content-container img {
    display: none;
    @media (min-width: 993px) {
      display: block;
    }
  }
}
