@use "../utils" as util;
@import "../utils";

//SIMPLE CONTACT FORM
.contact-form {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: space-around;

	&__header {
		@include util.font-set--fs-fw-lh(cardText, regular, "1.5");
	}

	&__form-input::placeholder {
		color: map-get($colors, "silverChalice") !important;
		@include util.font-set--fs-fw-lh(endnote, regular, "1.5");
	}

	&__contents {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
	}
}

//SCHECULE A TOUR CARD
.tour-card {
	flex-direction: column;
	justify-content: center;
	padding: 32px;
	order: 1;
	align-self: stretch;
	flex-grow: 1;
	background: map-get($colors, "royalBlue");
	border-radius: 16px;
	width: 100%;

	&__content-wrapper {
		gap: 32px;
	}

	&__heading {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 16px;
		color: map-get($colors, "white");

		h3 {
			@include util.ywh-h3-bold;
		}

		img {
			width: 50px;
		}
	}

	.select-btn {
		@include util.ywh-button-regular;

		&--active {
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 16px 16px;
			background: map-get($colors, "azure");
			border-width: 1px 0px 1px 1px;
			border-style: solid;
			border-color: map-get($colors, "conflowerBlue");
			border-radius: 4px 0px 0px 4px;
			color: map-get($colors, "white");
			width: 100%;
		}

		&--regular {
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 16px 16px;
			background: map-get($colors, "mystic");
			border-width: 1px 1px 1px 0px;
			border-style: solid;
			border-color: map-get($colors, "cornflowerBlue");
			border-radius: 0px 4px 4px 0px;
			width: 100%;
		}

		h6 {
			@include util.ywh-h6-bold;
			margin: 0;
		}
	}

	input[placeholder],
	textarea[placeholder],
	select {
		color: map-get($colors, "dimGray") !important;
		@include util.ywh-caption;
	}

	&__month {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px;
		border: 1px solid map-get($colors, "kashmirBlue");
		border-radius: 8px;
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;

		h5 {
			@include util.ywh-h5-bold;
			text-align: center;
			color: #ffffff;
			flex: none;
			order: 0;
			flex-grow: 1;
		}
	}

	&__day {
		@include util.ywh-endnote;
		text-transform: uppercase;
		color: map-get($colors, "white");
		margin-left: 35px;
	}

	.date-card {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;
		gap: 16px;
		flex: none;
		order: 1;
		flex-grow: 0;
	
		&__wrapper {
			flex-direction: row;
			align-items: center;
			padding: 0px;
			gap: 16px;
			flex-grow: 1;
		}

		&--active {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 8px;
			background-color: map-get($colors, "azure");
			color: map-get($colors, "white");
			border-radius: 8px;
			flex: none;
			order: 0;
			align-self: stretch;
			flex-grow: 1;
			max-width: 112px;
			margin-right: 8px;
			margin-left: 8px;
			text-align: center;
			display: flex !important;
			cursor: pointer;
		}

		&--normal {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 8px;
			background-color: map-get($colors, "white");
			border-radius: 8px;
			flex: none;
			order: 0;
			align-self: stretch;
			flex-grow: 1;
			max-width: 112px;
			margin-right: 8px;
			margin-left: 8px;
			text-align: center;
			display: flex !important;
			cursor: pointer;

		}

		small {
			@include util.ywh-nav-text;
		}

		h3 {
			@include util.ywh-h3-bold;
		}

		&__nav {
			img {
				width: 16px;
			}
		}
	}

	& .slick-slider {
		max-width: 385px;
		width: 100% !important;
		margin: 0 auto;

		& .slick-prev, & .slick-next {
			width: 16px !important;
			height: 27px !important;
		}
	}
}
