@use "../utils" as util;
@import "../utils";

.gallery {
	width: 80%;
	margin: auto;
}

.d-block {
	border-radius: 16px;
}
