@use "../utils" as util;
@import "../utils";

.hero {
	display: flex;
	flex-direction: row;
	padding: 0px;
	max-width: 1900px;
	max-height: 700px;
	margin: auto;

	&__left-col {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 50%;

		button {
			position: absolute;
			background-color: transparent;
			border: none;
			display: flex;

			img {
				width: 100px;
			}
		}

		img {
			max-width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__right-col {
		display: flex;
		flex-direction: column;
		max-width: 50%;

		.right-col__top {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			height: 50%;

			.btn-overlay {
				position: absolute;
				padding: 10px 15px 10px 15px;
				border-radius: 8px;
				background-color: map-get($colors, "white");
				margin: 0 20px 20px 0;
				border: none;
				transition-duration: 500ms;

				img {
					width: 30px;
				}

				&:hover {
					scale: 1.1;
					transition-duration: 500ms;
				}
			}

			.btn-overlay-text {
				@include util.ywh-button-regular;
				margin: 0;
				font-weight: semi-bold;
			}
		}

		.right-col__bottom {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			position: relative;
			height: 50%;

			button {
				position: absolute;
				border: none;
				background-color: transparent;

				&:hover {
					scale: 1.1;
					transition-duration: 500ms;
				}

				img {
					width: 60px;
					margin: 20px 20px 0 0;
				}
			}
		}

		.right-col-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.border-animate {
	position: absolute;
	width: 100px;
	height: 100px;
	border: 10px solid rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	animation: pulse-border 1.5s linear infinite;

	&--border-1 {
		animation-delay: 1s;
		opacity: 1;
	}

	&--border-2 {
		animation-delay: 1.5s;
		opacity: 0;
	}
}

// .hero-section {
// 	display: flex;
// 	justify-content: center;

// 	.hero {
// 		display: flex;
// 		max-width: 1900px;

// 		img {
// 			width: 100%;
// 		}

// 		&__col-1 {
// 			display: flex;

// 			.video-image {
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;

// 				.play-btn {
// 					position: absolute;
// 					z-index: 10;

// 					button {
// 						border: none;
// 						background-color: transparent;

// 						img {
// 							width: 100px;
// 						}
// 					}
// 				}

// 				.video-cover-image {
// 					display: flex;
// 					align-self: stretch;

// 					img {
// 						object-fit: cover;
// 					}
// 				}
// 			}
// 		}

// 		&__col-2 {
// 			&--top-img {
// 				position: relative;
// 			}
// 		}

// 		&__btn-overlay {
// 			padding: 10px 15px 10px 15px;
// 			border-radius: 5px;
// 			background-color: map-get($colors, "white");
// 			margin: 0 20px 20px 0;
// 			border: none;
// 			transition-duration: 500ms;

// 			img {
// 				width: 30px;
// 			}

// 			&:hover {
// 				scale: 1.1;
// 				transition-duration: 500ms;
// 			}
// 		}

// 		&__btn-overlay-text {
// 			@include util.ywh-button-regular;
// 			margin: 0;
// 			font-weight: semi-bold;
// 		}
// 	}
// }
